:root {
  --form-control-border-radius: 4px;
  --form-control-border-bottom: 0;
  --form-control-focus-shadow: none;
  --form-control-focus-border-bottom: 0;
  --form-control-bg-color: #fff;
  --form-control-hover-bg-color: #fff;
}

.game {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 80px);
  overflow: hidden;
  max-width: 960px;
  font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica, sans-serif !important;
}

.game * {
  font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica, sans-serif !important;
}

.mainSection {
  position: relative;
  display: flex;
  flex-grow: 1;
  background-size: cover;
  background-position: center;
}

.secondSection {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 300px;
  flex-grow: 0;
  box-sizing: border-box;
  padding: 15px;
  padding-bottom: 35px;
  background-color: #fff;
}

.smallSecondSection {
  min-height: 220px;
}

.button {
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  outline: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  font-weight: normal;
}

.button::after {
  content: '';
  will-change: left;
  position: absolute;
  transform: skewX(-22deg);
  width: 45px;
  height: 100%;
  opacity: 0;
  left: 0%;
  top: 0;
  animation-delay: 2s;
  pointer-events: none;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.6) 100%);
  animation-name: button-blick;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.button:hover {
  filter: brightness(0.95);
}

.disabledStartButton {
  cursor: default;
}

.disabledStartButton:hover {
  filter: none;
}

.disabledStartButton::after {
  display: none;
}

.buttonLoader {
  width: 18px;
  height: 18px;
}

.emailInput {
  width: 100%;
  max-width: 400px;
  margin: 25px auto 0;
  outline: none;
  border-radius: 5px;
  padding: 14px;
  font-size: 16px;
  border: 1px solid;
  color: #000;
  box-sizing: border-box;
}

.phoneInput {
  margin-top: 25px;
  width: 100%;
}

.phoneInput > div {
  border: 1px solid #000;
}

.emailInputError {
  box-shadow: 0 0 1px 1px red;
  border-color: red;
}

.title {
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  margin-bottom: 15px;
  font-weight: bold;
  word-break: break-word;
  user-select: none;
}

.subtitle {
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  font-weight: normal;
  word-break: break-word;
  user-select: none;
}

.closeGameIcon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
}

.error {
  margin-top: 10px;
  font-size: 12px;
  color: red;
}

.linkToPrize {
  color: #fff;
  border-bottom: 1px dashed #fff;
}

.linkToPrize:hover {
  opacity: 0.9;
}

.madeBy {
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 12px;
  user-select: none;
}

.link {
  text-decoration: none !important;
}

.gdprCheckboxWrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}

.gdprCheckbox {
  margin-right: 10px !important;
  cursor: pointer;
}

.gdprCheckboxError {
  outline: 1px solid red;
}

.gdprCheckboxLabel {
  cursor: pointer;
  font-size: 12px;
  opacity: 0.7;
  color: #000;
}

.controlsWrap {
  width: 100%;
  max-width: 400px;
}

.shake {
  animation: shake 0.15s linear 0s 3;
}

.prizesWrapper {
  display: flex;
  padding: 0 15px;
}

.prizesSliderWrapper {
  position: relative;
  left: -15px;
  display: block;
  width: calc(100% + 30px);
  margin-top: 30px;
  pointer-events: none;
}

.prizeSliderTitle {
  font-size: 18px;
  line-height: 20px;
  color: #000;
  margin: 0 15px 15px;
  padding-top: 30px;
  font-weight: bold;
  user-select: none;
}

.prizePreviewImg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 0 auto 5px;
  border-radius: 15px;
  background-color: #4986cc;
  color: #fff;
}

.prizePreview {
  flex-shrink: 0;
  width: 25%;
  outline: none;
}

.prizePreviewName {
  padding: 0 4px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  user-select: none;
}

.mainSectionDisabled {
  position: relative;
}

.gap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.error {
  margin-top: 16px;
  font-size: 14px;
}

.tabsWrapper {
  margin-bottom: 16px;

  > div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-2px);
  }

  75% {
    transform: translateX(2px);
  }

  100% {
    transform: translateX(0);
  }
}

@media screen and (min-width: 360px) {
  .title {
    font-size: 20px;
    line-height: 24px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 18px;
  }
}

@media screen and (min-width: 768px) {
  .game {
    flex-direction: row;
    height: auto;
    min-height: auto;
  }

  .mainSection {
    flex-shrink: 1;
    width: 560px;
    height: 560px;
  }

  .secondSection {
    width: 340px;
    padding: 80px 25px 30px;
    justify-content: flex-start;
  }

  .secondSectionControls {
    max-width: 290px;
  }

  .title {
    font-size: 24px;
    line-height: 34px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 20px;
  }

  .prizesSliderWrapper {
    left: -25px;
    width: calc(100% + 50px);
  }

  .prizeSliderTitle {
    margin: 0 25px 25px;
  }
}

@media screen and (min-width: 960px) {
  .mainSection {
    width: 620px;
    height: 620px;
  }
}

@keyframes button-blick {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.3;
  }
  40% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 0;
  }
}
