.mainSection {
  max-height: 75vw;
  min-height: 250px;
}

.game {
  width: 100%;
  height: 100%;
}

.pointer {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 40px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(180deg) translateX(50%);
}

.wheelContainer {
  position: absolute;
  top: 0;
  width: 100%;
  transform: translateY(-50%) scale(1.1);
}

.wheelSvg {
  transform: translateZ(0);
}

@media screen and (min-width: 375px) {
  .wheelContainer {
    width: 100%;
    transform: translateY(-50%) scale(1.3);
  }
}

@media screen and (min-width: 768px) {
  .mainSection {
    max-height: initial;
  }

  .wheelContainer {
    width: 95%;
    top: 50%;
    left: 50%;
    bottom: initial;
    transform: translateY(-50%) translateX(-50%) scale(0.95);
  }
}
